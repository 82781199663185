import './App.css';
import React, {lazy, useEffect} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

import './i18n';
import lazyRetry from "./services/lazyRetry";
import {usePostHog} from 'posthog-js/react'

const appTheme = createTheme({
    typography: {
        fontFamily: "Inter",
    },
    palette: {
        /*
        primary: {
            main: '#488a99',
            dark: '#2b5a6b',
            light: '#6ab3c0'
        },
        secondary: {
            main: '#5B7586',
            dark: '#36454F',
            light: '#A7B8C3'
            //primrose pure
        },
        third: {
            main: '#DBAe57',
            dark: '#b48a3f',
            light: '#fadaa2'
        },

        four:{
            main: '#808080',
            dark: '#525252',
            light: '#eae9e9'
        },

         */
        primary: {

            main: "#B2Dbd5",
            dark: "#678a85",
            light: "#d9ebe9"
        },
        secondary: {
            main: "#2b616d",
            dark: "#1a3b42",
            light: "#4d8c9a"
        },
        third: {
            main: '#FFFFFF',
            dark: '#FFFFFF',
            light: '#FFFFFF'
        },
        four: {
            main: "#FA8D62",
            dark: "#c86a44",
            light: "#fcbfa0"
        },
        white: {
            main: "#FFFFFF"
        },
        green: {
            main: '#5FAD56'
        },
        yellow:
            {main: '#FEC619'},
        orange:
            {main: '#FF5F24'},
        purple:
            {main: '#4D5CB5'}
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    [`&.Mui-disabled`]: {
                        backgroundColor: '#b2bcc5 !important'
                    }

                }
            }
        }
    }
});

const PropertyListPage = lazy(() => lazyRetry(() => import('./view/PropertyListPage')));
const LoginPage = lazy(() => lazyRetry(() => import('./view/LoginPage')));
const DashboardPage = lazy(() => lazyRetry(() => import('./view/DashboardPage')));
const PropertyPage = lazy(() => lazyRetry(() => import('./view/PropertyPage')));
const BookingPage = lazy(() => lazyRetry(() => import('./view/BookingPage')));
const PropertyListEmbedPage = lazy(() => lazyRetry(() => import('./view/PropertyListEmbedPage')));


function App() {

    const posthog = usePostHog()


    //if path include embed set background to transparent
    useEffect(() => {


        if (window.location.pathname.includes("embed")) {
            document.body.style.backgroundColor = "transparent"
        }

        if(window.location.hostname === "app.beyondflats.com") {
            window.posthog = posthog
        }
    }, [])

    return (
        <ThemeProvider theme={appTheme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/property-list" element={<PropertyListPage/>}/>
                    <Route path="*" element={<PropertyListPage/>}/>
                    <Route path="/embed" element={<PropertyListEmbedPage/>}/>
                    <Route path="/dashboard" element={<DashboardPage/>}/>
                    <Route path={"/login"} element={<LoginPage/>}/>
                    <Route path={"/reserva"} element={<BookingPage/>}/>
                    <Route path={"/propiedades/*"} element={<PropertyPage/>}/>


                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App;
