import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init


//get the language from the browser
const language = navigator.language.split(/[-_]/)[0];  // language without region code

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        lng: language,
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            es: {
                translation: {
                    component: {
                        map: {
                            showMap: "Ver mapa",
                            hideMap: "Ocultar mapa",
                            seeAllProperties: "Ver todas las propiedades",
                        },
                        dashboard: {
                            bookingStatuses: {
                                "PENDING_OWNER_APPROVAL": "Pendiente de aprobación del propietario",
                            }
                        },
                        propertyListPage: {
                            totalProperties: "{{count}} propiedades disponibles",
                            showMap: "Mostrar mapa",
                            hideMap: "Ocultar mapa",
                            searchLabel: "Buscar por población, código postal...",
                            display:
                                {
                                    minSurface: "¿Cual es la superficie mínima que consideras?",
                                    minRooms: "¿Cuantas habitaciones estás buscando?",
                                    minRoomsBaths: "¿Cuantas habitaciones y baños estás buscando?",
                                    minBathrooms: "¿Cuantos baños necesitas?",
                                    minOccupation: "¿Qué capacidad de personas consideras?",
                                    minPrice: "¿Cual es el tu presupuesto?",
                                    areas: "¿En que zonas te gustaría vivir?",
                                    featuresIncluded: "¿Qué características te gustaría que tuviera?",
                                    checkIn: "¿Cuando te gustaría entrar?",
                                    checkOutOptional: "¿Tienes fecha de salida? (Opcional)",
                                },
                            filters: {
                                neighborhoods: "Barrios",
                                surface: "Superficie",
                                rooms: "Habitaciones",
                                price: "Alquiler",
                                people: "Personas",
                                features: "Características",
                                checkIn: "Entrada",
                                zones: "Zonas",
                            },
                            noProperties: "No hay propiedades disponibles",
                        },
                        propertyPage: {
                          bedroom: "Dormitorio",
                            singlebed90cm: "Cama individual 90cm",
                            doublebed135cm: "Cama doble 135cm",
                            doublebed140cm: "Cama doble 140cm",
                            doublebed160cm: "Cama doble 160cm",
                            doublebed150cm: "Cama doble 150cm",
                            equipment: "Equipamiento",
                            whereYouSleep: "Donde dormirás",
                            description: "Descripción",
                            building: "Edificio",
                            services: "Servicios",
                            interestInformation: "Información de interés",
                            aboutTheArea: "Sobre el barrio",
                            from: "Desde",
                            midTerm: "32 días a 23 meses",
                            longTerm: "12 meses o más",
                            people: "personas",
                            baths: "baños",
                            bath: "baño",
                            rooms: "habitaciones",
                            room: "habitación",
                            NW: "Noroeste",
                            NE: "Noreste",
                            SW: "Suroeste",
                            SE: "Sureste",
                            N: "Norte",
                            S: "Sur",
                            W: "Oeste",
                            E: "Este",
                            floor: "planta",
                            minutes: "minutos",
                            back: "Volver",
                            share: "Compartir",
                            shareWhatsapp: "Compartir por Whatsapp",
                            monthlyRent: "Renta por mes",
                            firstMonthRent: "Renta primer mes",
                            utilitiesFirstMonth: "Consumos primer mes",
                            deposit: "Fianza",
                            totalBookingAmount: "Importe total de la reserva",
                            agencyFee: "Honorarios agencia",
                            totalCharges: "Cargos totales",
                            aboutTheNeighborhood: "Sobre el barrio",
                            total: "Total",
                            toBookTheFlat: "Para reservar el piso",
                            monthlySupplies: "Suministros por mes",
                            monthlySubtotal: "Subtotal mensual",
                            vatInclShort: "21% IVA incl.",
                            itp: "ITP",
                            taxes: "Impuestos",
                            itpPercent: "(0.5% Reserva)",
                            firstMonthlyPayment: "Renta durante estancia",
                            secondMonthRent: "Renta segundo mes",
                            secondMonthUtilities: "Consumos segundo mes",
                            booking: "Reserva",
                            bookingCompleted: "Reserva realizada",
                            nextPayment: "Antes de entrar",
                            days: "días",
                            refundable: "Reembolsable",
                            requestBooking: "Solicitar reserva",
                            checkingAvailability: "Comprobando disponibilidad...",
                            month: "mes",
                            availableDateEntry: "Fecha de entrada disponible",
                            entry: "Entrada",
                            exit: "Salida",
                            selectDates: "Selecciona fechas",
                            available: "Disponible",
                            clearDates: "Borrar fechas",
                            nextAvailabilityFrom: "Próxima disponibilidad desde",
                            seeTotal: "Ver total",
                            video: "Video",
                            plan: "Plano",
                            seePriceList: "Ver precios",
                            datesNotAvailable:  "No hay disponibilidad para las fechas seleccionadas",
                            pets_allowed: "Admiten mascotas",
                            pets_allowed_long: "Los animales de asistencia siempre están permitidos,",
                            smoking_apartment_prohibited: "Prohibido fumar en el alojamiento",
                            checkin_checkout_policy: "Hora de entrada (check-in) a partir de las 16h. Hora de salida (check-out) a las 11am",
                            booking_cancelation_refund: "Cancelación de reserva y reembolso",
                            booking_cancelation_refund_long: "Referirse a la Política de Cancelación. Existe una política semi-flexible según el tiempo entre la cancelación y la fecha de entrada",
                            pets_not_allowed: "No se admiten mascotas",
                            "no_parties/events_allowed": "No se permiten fiestas/eventos",
                            referTo: "Consulte nuestras",
                            hotItWorks: "Cómo funciona",
                            understoodRequest: "¡Entendido! Solicitar",
                            prePaymentBookingIntro: "Haz una pre-reserva del piso por 250€",
                            prePaymentBookingBody: "(deducibles de la Reserva) para bloquear el piso durante 24-48h. Validaremos los documentos compartidos con el propietario y nos pondremos en contacto en breve.",
                            prePaymentBookingLast: "En el caso de que la aplicación sea rechazada, se devolverá el dinero en 5 días hábiles.",
                            requestPreBooking: "Hacer Pre-Reserva",
                            contactUsBooking: "<b>Contáctanos</b> por Whatsapp/llamanada en el teléfono <a href=\"tel:+34634722338\"><b>(+34) 634 722 338</b></a> o por correo <a href='mailto:info@beyondflats.com'> <b>info@beyondflats.com</b></a>.",
                            weContactYou: "Te contactamos",
                            firstName: "Nombre",
                            lastName: "Apellidos",
                            email: "Email",
                            phone: "Teléfono",
                            prefix: "Prefijo",
                            country: "País",
                            zipCode: "Código postal",
                            city: "Ciudad",
                            address: "Dirección",
                            complete: "Completar",
                            documentType: "Tipo de documento",
                            documentNumber: "Número de documento",
                            weContactYouIntro: "Déjanos tus datos y te contactaremos en breve para ayudarte con tu reserva.",
                            acceptPrivacyPolicy: "Acepto la política de privacidad y los términos y condiciones",
                            weContactYouThankYou: "¡Gracias! Nos pondremos en contacto contigo en breve.",
                            bookingCreation: "Creación de reserva",
                            toPayNow: "Para pagar ahora",
                            paidNow: "Pagado",

                        },
                        propertyCard: {
                            availableNow: "Disponible ahora",
                            availableFrom: "Disponible",
                            individual: "individual",
                            individuals: "individuales",
                            double: "doble",
                            doubles: "dobles",
                            bath: "baño",
                            baths: "baños",
                            bathrooms: "Baños",
                            month: "mes",
                            seeMap: "Ver en ",
                            singleBedroom: "Habitación individual",
                            doubleBedroom: "Habitación doble",
                            bedrooms: "Habitaciones",
                            microwave: "Microondas",
                            oven: "Horno",
                            airConditioning: "Aire acondicionado",
                            heating: "Calefacción",
                            washingMachine: "Lavadora",
                            dryer: "Secadora",
                            dishwasher: "Lavavajillas",
                            elevator: "Ascensor",
                            balcony: "Balcón",
                            wifi: "Wifi",
                            privateTerrace: "Terraza privada",
                            swimmingPool: "Piscina",
                            petFriendly: "Se admiten mascotas",
                            seeViews: "Vistas al mar",
                            parking: "Parking",
                            wheelchairAccesibility: "Accesible para silla de ruedas",
                            room: "habitación",
                            rooms: "habitaciones",
                            furnished: "Amueblado",
                            fridge: "Frigorífico",
                            tv: "TV",
                            ac: "Aire acondicionado",
                            coffeeMachine: "Cafetera",
                            vacuumCleaner: "Aspiradora",
                            cityViews: "Vistas a la ciudad",
                            terrace: "Terraza",
                            cookingPlate: "Placa de cocción",
                            cookingGriddle: "Plancha",
                            shower: "Ducha",
                            doubleCrystal: "Doble cristal",
                            hairDryer: "Secador de pelo",
                            reinforcedDoor: "Puerta blindada",
                            alarm: "Alarma",
                            porter: "Portero",
                            moreInfo: "Más info"

                        }
                    },
                    utils:{
                      selectAll: "Seleccionar todos",
                        deselectAll: "Deseleccionar todos",
                    },
                    backend: {
                        properties: {
                            inventory: {
                                "Bathtub": "Bañera",
                                "Shower": "Ducha",
                                "Bidet": "Bidé",
                                "WC": "Inodoro",
                                "Sink": "Lavabo",
                                "Hair Dryer": "Secador de pelo",
                                "Weighting machine": "Báscula",
                                "Towels": "Toallas",
                                "Floor Towel": "Toalla de suelo",
                                "Bed": "Cama",
                                "Chair": "Silla",
                                "Table": "Mesa",
                                "Cupboard": "Armario",
                                "Bed sheets": "Sábanas",
                                "Pillows": "Almohadas",
                                "Blankets": "Mantas",
                                "Edredon": "Edredón",
                                "Dining table": "Mesa de comedor",
                                "Chairs": "Sillas",
                                "Sofa": "Sofá",
                                "Armchair": "Sillón",
                                "Buffet": "Bufé",
                                "TV cabinet": "Mueble de televisión",
                                "Lamps (details)": "Lámparas (detalles)",
                                "Sound system": "Sistema de sonido",
                                "TV (details)": "Televisión (detalles)",
                                "Paintings": "Cuadros",
                                "Decoration (details)": "Decoración (detalles)",
                                "Portable fans": "Ventiladores portátiles",
                                "Portable heater": "Calefactor portátil",
                                "Plant Pots (details)": "Macetas (detalles)",
                                "Desk": "Escritorio",
                                "Dishwasher": "Lavavajillas",
                                "Refrigerator": "Refrigerador",
                                "Freezer": "Congelador",
                                "Oven": "Horno",
                                "Stove": "Cocina",
                                "Cups/Glasses (details)": "Tazas/Vasos (detalles)",
                                "Mugs": "Tazas",
                                "Bowls": "Boles",
                                "Pots": "Cazuelas",
                                "Pans": "Sartenes",
                                "Cutlery (details)": "Cubertería (detalles)",
                                "Other Utensils": "Otros utensilios",
                                "Plates (details)": "Platos (detalles)",
                                "Hot water kettle": "Hervidor de agua",
                                "Blender": "Licuadora",
                                "Coffee maker": "Cafetera",
                                "Rice maker": "Arrocera",
                                "Toaster": "Tostadora",
                                "Microwave": "Microondas",
                                "Washer (details)": "Lavadora (detalles)",
                                "Dryer": "Secadora",
                                "Drying rack for clothing": "Tendedero para la ropa",
                                "Hangers": "Perchas",
                                "Iron": "Plancha",
                                "Iron board": "Tabla de planchar",
                                "Baby bath": "Bañera para bebé",
                                "High chair": "Trona",
                                "Other (details)": "Otros (detalles)"
                            }
                        }
                    }
                }
            },en: {
                translation: {
                    component: {
                        map:{
                            showMap: "Show map",
                            hideMap: "Hide map",
                            seeAllProperties: "See all properties",
                        },
                        dashboard: {
                            bookingStatuses: {
                                "PENDING_APPROVAL": "Pending owner approval",
                            }
                        },
                        propertyListPage: {
                            totalProperties: "{{count}} properties available",
                            searchLabel: "Search by city, postal code...",
                            display:
                                {
                                    minSurface: "What is the minimum surface you consider?",
                                    minRooms: "How many rooms are you looking for?",
                                    minRoomsBaths: "How many rooms and toilets are you looking for?",
                                    minBathrooms: "How many toilets do you need?",
                                    minOccupation: "What capacity of people do you consider?",
                                    minPrice: "What is your budget?",
                                    areas: "In which areas would you like to live?",
                                    featuresIncluded: "What features would you like it to have?",
                                    checkIn: "When would you like to move in?",
                                    checkOutOptional: "Do you have a move out date? (Optional)",
                                },
                            filters: {
                                neighborhoods: "Districts",
                                surface: "Surface",
                                rooms: "Rooms",
                                price: "Rent",
                                people: "People",
                                features: "Features",
                                checkIn: "Check in",
                                zones: "Zones",
                                },
                            noProperties: "There are no properties available",
                            },
                        propertyPage: {
                            bedroom: "Bedroom",
                            singlebed90cm: "Single bed 90cm",
                            doublebed135cm: "Double bed 135cm",
                            doublebed140cm: "Double bed 140cm",
                            doublebed160cm: "Double bed 160cm",
                            doublebed150cm: "Double bed 150cm",
                            equipment: "Amenities",
                            whereYouSleep: "Sleeping arrangement",
                            description: "Description",
                            building: "Building",
                            services: "Services",
                            interestInformation: "Things to know",
                            aboutTheNeighborhood: "About the neighborhood",
                            from: "From",
                            midTerm: "32 days to 23 months",
                            longTerm: "12 months or more",
                            people: "people",
                            baths: "toilets",
                            bath: "toilet",
                            rooms: "rooms",
                            room: "room",
                            NW: "Northwest",
                            NE: "Northeast",
                            SW: "Southwest",
                            SE: "Southeast",
                            N: "North",
                            S: "South",
                            W: "West",
                            E: "East",
                            floor: "floor",
                            minutes: "minutes",
                            back: "Back",
                            share: "Share",
                            shareWhatsapp: "Share via Whatsapp",
                            monthlyRent: "Monthly rent",
                            firstMonthRent: "First month rent",
                            utilitiesFirstMonth: "Utilities first month",
                            deposit: "Deposit",
                            agencyFee: "Agency fee",
                            totalCharges: "Total charges",
                            total: "Total",
                            toBookTheFlat: "To book the flat",
                            monthlySupplies: "Monthly supplies",
                            monthlySubtotal: "Monthly subtotal",
                            totalBookingAmount: "Total booking amount",
                            vatInclShort: "21% VAT incl.",
                            itp: "ITP",
                            itpPercent: "(0.5%  Booking)",
                            taxes: "Taxes",
                            refundable: "Refundable",
                            requestBooking: "Request booking",
                            checkingAvailability: "Checking availability...",
                            month: "month",
                            bookingCompleted: "Booking completed",
                            booking: "Booking",
                            availableDateEntry: "Available entry date",
                            entry: "Entry",
                            exit: "Exit",
                            selectDates: "Select dates",
                            available: "Available",
                            clearDates: "Clear dates",
                            nextAvailabilityFrom: "Next availability from",
                            seeTotal: "See total",
                            video: "Video",
                            plan: "Plan",
                            seePriceList: "See prices",
                            datesNotAvailable: "There is no available for selected dates",
                            pets_allowed: "Pets allowed",
                            pets_allowed_long: "Assistance animals are always allowed",
                            smoking_apartment_prohibited: "No smoking allowed",
                            checkin_checkout_policy: "Move in after 4pm & Move out by 11am",
                            booking_cancelation_refund: "Booking cancellation and refunds",
                            booking_cancelation_refund_long: "Refer to the Cancellation Policy. There is a semi-flexible policy depending on the time between cancellation and check-in date",
                            pets_not_allowed: "No pets allowed",
                            "no_parties/events_allowed": "No parties or events allowed",
                            referTo: "Refer to the",
                            hotItWorks: "How it works",
                            understoodRequest: "Understood! Request booking",
                            nextPayment: "Before moving in",
                            firstMonthlyPayment: "Rent during stay",
                            prePaymentBookingIntro: "Make a pre-booking of the flat for 250€",
                            prePaymentBookingBody: "(deductible from the Booking) to block the flat for 24-48h. We will validate the documents shared with the owner and contact you shortly.",
                            prePaymentBookingLast: "In case the application is rejected, the money will be refunded in 5 business days.",
                            requestPreBooking: "Request Pre-Booking",
                            contactUsBooking: "<b>Contact us</b> via Whatsapp/call at <a href=\"tel:+34634722338\"><b>(+34) 634 722 338</b></a> or email <a href='mailto:info@beyondflats.com'> <b>info@beyondflats.com</b></a>.",
                            weContactYou: "We contact you",
                            firstName: "First name",
                            lastName: "Last name",
                            email: "Email",
                            phone: "Phone",
                            prefix: "Prefix",
                            complete: "Complete",
                            weContactYouIntro: "Leave us your details and we will contact you shortly to help you with your booking.",
                            acceptPrivacyPolicy: "I accept the privacy policy and terms and conditions",
                            weContactYouThankYou: "Thank you! We will contact you shortly.",
                            bookingCreation: "Booking creation",
                            toPayNow: "To pay now",
                            paidNow: "Paid",
                            secondMonthRent: "Second month rent",
                            secondMonthUtilities: "Second month utilities",


                        },
                        propertyCard: {
                            availableNow: "Available now",
                            availableFrom: "Available",
                            individual: "single",
                            individuals: "singles",
                            double: "double",
                            doubles: "doubles",
                            bath: "toilet",
                            baths: "toilets",
                            bathrooms: "toilets",
                            month: "month",
                            seeMap: "See in",
                            singleBedroom: "Single bedroom",
                            doubleBedroom: "Double bedroom",
                            bedrooms: "Bedrooms",
                            microwave: "Microwave",
                            oven: "Oven",
                            airConditioning: "Air conditioning",
                            heating: "Heating",
                            washingMachine: "Washing machine",
                            dryer: "Dryer",
                            dishwasher: "Dishwasher",
                            elevator: "Elevator",
                            balcony: "Balcony",
                            wifi: "Wifi",
                            privateTerrace: "Private terrace",
                            swimmingPool: "Swimming pool",
                            petFriendly: "Pet friendly",
                            seeViews: "Sea views",
                            parking: "Parking",
                            wheelchairAccesibility: "Wheelchair accessible",
                            room: "room",
                            rooms: "rooms",
                            furnished: "Furnished",
                            fridge: "Fridge",
                            tv: "TV",
                            ac: "AC",
                            coffeeMachine: "Coffee machine",
                            vacuumCleaner: "Vacuum cleaner",
                            cityViews: "City views",
                            terrace: "Terrace",
                            cookingPlate: "Cooktop",
                            cookingGriddle: "Cooking griddle",
                            shower: "Shower",
                            doubleCrystal: "Double glass",
                            hairDryer: "Hair dryer",
                            reinforcedDoor: "Reinforced door",
                            alarm: "Alarm",
                            porter: "Concierge",
                            moreInfo: "More info",


                        }
                    },
                    utils:{
                        selectAll: "Select all",
                        deselectAll: "Deselect all"
                    },
                    backend: {
                        properties: {
                            inventory: {
                                "Bathtub": "Bathtub",
                                "Shower": "Shower",
                                "Bidet": "Bidet",
                                "WC": "WC",
                                "Sink": "Sink",
                                "Hair Dryer": "Hair Dryer",
                                "Weighting machine": "Weighting machine",
                                "Towels": "Towels",
                                "Floor Towel": "Floor Towel",
                                "Bed": "Bed",
                                "Chair": "Chair",
                                "Table": "Table",
                                "Cupboard": "Cupboard",
                                "Bed sheets": "Bed sheets",
                                "Pillows": "Pillows",
                                "Blankets": "Blankets",
                                "Edredon": "Edredon",
                                "Dining table": "Dining table",
                                "Chairs": "Chairs",
                                "Sofa": "Sofa",
                                "Armchair": "Armchair",
                                "Buffet": "Buffet",
                                "TV cabinet": "TV cabinet",
                                "Lamps (details)": "Lamps (details)",
                                "Sound system": "Sound system",
                                "TV (details)": "TV (details)",
                                "Paintings": "Paintings",
                                "Decoration (details)": "Decoration (details)",
                                "Portable fans": "Portable fans",
                                "Portable heater": "Portable heater",
                                "Plant Pots (details)": "Plant Pots (details)",
                                "Desk": "Desk",
                                "Dishwasher": "Dishwasher",
                                "Refrigerator": "Refrigerator",
                                "Freezer": "Freezer",
                                "Oven": "Oven",
                                "Stove": "Stove",
                                "Cups/Glasses (details)": "Cups/Glasses (details)",
                                "Mugs": "Mugs",
                                "Bowls": "Bowls",
                                "Pots": "Pots",
                                "Pans": "Pans",
                                "Cutlery (details)": "Cutlery (details)",
                                "Other Utensils": "Other Utensils",
                                "Plates (details)": "Plates (details)",
                                "Hot water kettle": "Hot water kettle",
                                "Blender": "Blender",
                                "Coffee maker": "Coffee maker",
                                "Rice maker": "Rice maker",
                                "Toaster": "Toaster",
                                "Microwave": "Microwave",
                                "Washer (details)": "Washer (details)",
                                "Dryer": "Dryer",
                                "Drying rack for clothing": "Drying rack for clothing",
                                "Hangers": "Hangers",
                                "Iron": "Iron",
                                "Iron board": "Iron board",
                                "Baby bath": "Baby bath",
                                "High chair": "High chair",
                                "Other (details)": "Other (details)"
                            }

                        }
                    }
                }
            }
        }
    });


export default i18n;
